import {inject} from '@angular/core';
import {CanActivateFn, Router, UrlTree} from '@angular/router';
import {map, take} from 'rxjs';

import {PrintShopsService} from '@vmi/core';
import {PrintShop, ROUTE_PATH} from '@vmi/shared-models';

export function canActivateVmiAccessGuard(isAccessDeniedPage = false): CanActivateFn {
    return () => {
        const printShopsService = inject(PrintShopsService);
        const router = inject(Router);

        if (printShopsService.hasInitializedPrintShops) {
            return canActivate(printShopsService.availablePrintShops, isAccessDeniedPage, router);
        } else {
            return printShopsService.setUpPrintShops().pipe(
                take(1),
                map((printShops) => canActivate(printShops, isAccessDeniedPage, router))
            );
        }
    };
}

function canActivate(printShops: PrintShop[], isAccessDeniedPage: boolean, router: Router): boolean | UrlTree {
    const hasPrintShops = !!printShops.length;

    if (isAccessDeniedPage) {
        return !hasPrintShops || router.createUrlTree([]);
    }

    return hasPrintShops || router.createUrlTree([ROUTE_PATH.accessDenied]);
}
