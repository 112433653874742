@if (isGlobalLoadingSpinnerActive$ | async) {
    <div class="global-loading-spinner__wrapper">
        <vmi-loading-indicator></vmi-loading-indicator>
    </div>
}

<div class="hdmui-top-structure">
    <header>
        @if (initialLoadDone && (mainMenuVisible$ | async)) {
            <hdmui-cloud-toolbar
                (logoutClick)="signOut()"
                (notificationsRemove)="onNotificationRemove($event)"
                (sidenavAboutClick)="showAbout()"
                [appIcon]="'assets/svg/HDM-Inventory.svg'"
                [appId]="APP_ID"
                [appStage]="stage === 'prod' ? undefined : stage"
                [appTitle]="'inventory.app.title' | translate"
                [breadcrumbs]="breadcrumbs$ | async"
                [ccStage]="stage === 'prod' ? undefined : 'stage'"
                [currentUser]="toolbarUser"
                [darkModeText]="'*.dark_mode' | translate"
                [globalNotificationSettingsText]="'*.global_notification_settings' | translate"
                [heidelbergPlusHomeText]="'*.navigation.heidelberg_plus.home' | translate"
                [isSecondLevel]="isSecondLevel$ | async"
                [logoutText]="'*.logout' | translate"
                [notifications]="notifications"
                [profileText]="'*.profile_memberships' | translate"
                [showNotificationButton]="true"
                [showOrganizationText]="'*.organization.show' | translate"
                [showSidenavSettingsButton]="false"
                [sidenavAboutText]="'*.about' | translate"
            >
                <div customButtonContainer>
                    <button (click)="onContact()" [matTooltip]="'cc.contact' | translate" mat-icon-button>
                        <mat-icon svgIcon="hdmui:envelopeClose"></mat-icon>
                    </button>
                </div>
            </hdmui-cloud-toolbar>
        }

        @if (initialLoadDone && (mainMenuVisible$ | async) && (subMenuVisible$ | async)) {
            <app-nav [ngClass]="{ 'hdmui-background-1': (isSecondLevel$ | async) }" class="hdmui-subnav"></app-nav>
        }
    </header>

    @if (initialLoadDone) {
        <section class="hdmui-content hdmui-flex">
            <router-outlet></router-outlet>
            @if (!hideGlobalLegalLine) {
                <vmi-legal-line></vmi-legal-line>
            }
        </section>
    }
</div>
