import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { map, Observable, of, tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
    CloudToolbarBreadcrumb,
    CloudToolbarUser,
    LAUNCH_SCREEN,
    LaunchScreen,
    Membership,
    Notification,
} from '@heidelberg/hdmui-angular';
import { NotificationService, Stage } from '@heidelberg/control-center-frontend-integration';
import { CCAuthService, CCSession } from '@heidelberg/control-center-frontend-integration/auth';

import { AppConfigService, UserService } from '@vmi/core';
import { ContactFormDialogComponent, GlobalLoadingSpinnerService } from '@vmi/ui-smart';
import { AboutData, AboutDialogComponent } from '@vmi/ui-presentational';
import { ROUTE_PATH } from '@vmi/shared-models';

import { NavigationService } from './navigation/navigation.service';

interface PersonOrg {
    personId: string;
    organizationId: string;
}

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    public readonly APP_ID = 'vendor_managed_inventory';

    @ViewChild('drawer') drawer: MatSidenav | undefined;

    isSecondLevel$: Observable<boolean> = this.navigationService.level$.pipe(
        switchMap((result) => {
            const orgId = this.sessionService.getCurrentMembership()?.organizationId;
            return of(result === 2 && !!orgId);
        })
    );
    breadcrumbs$: Observable<CloudToolbarBreadcrumb[]> = this.navigationService.breadcrumbs$;
    mainMenuVisible$: Observable<boolean> | undefined = this.navigationService.mainMenuVisible$;
    subMenuVisible$: Observable<boolean> | undefined = this.navigationService.subMenuVisible$;
    isGlobalLoadingSpinnerActive$: Observable<boolean> = this.globalLoadingSpinnerService.isLoading$;

    notifications: Notification[] = [];

    toolbarUser: CloudToolbarUser | undefined;

    initialLoadDone = false;

    stage!: Stage;

    hideGlobalLegalLine = false;

    readonly #PAGES_TO_HIDE_GLOBAL_LEGAL_LINE = [`${ROUTE_PATH.deliveries}/${ROUTE_PATH.deliveryProcessing}`];

    constructor(
        @Inject(LAUNCH_SCREEN) private readonly launchScreen: LaunchScreen,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly translateService: TranslateService,
        private readonly appConfigService: AppConfigService,
        private readonly sessionService: CCAuthService,
        private readonly navigationService: NavigationService,
        private readonly notificationService: NotificationService,
        private readonly userService: UserService,
        private readonly globalLoadingSpinnerService: GlobalLoadingSpinnerService
    ) {
        this.stage = this.appConfigService.getStage();

        this.sessionService.currentSession$
            .pipe(
                untilDestroyed(this),
                tap((session) => {
                    this.toolbarUser = this.getToolbarUserFromSession(session);
                }),
                map((session) => {
                    const personId = session.user.id;
                    const organizationId = this.sessionService.getCurrentMembership()?.organizationId;

                    return { personId, organizationId } as PersonOrg;
                }),
                filter(({ organizationId }) => !!organizationId),
                tap(({ personId, organizationId }) => {
                    this.notificationService.setCurrentUser({ personId, organizationId });
                    const user = this.sessionService.getCurrentUser();
                    if (user) {
                        this.userService.initUserSession(user, organizationId);
                    }
                })
            )
            .subscribe();

        this.listenForHidingGlobalLegalLine();
    }

    ngOnInit(): void {
        this.notificationService.notifications$.pipe(untilDestroyed(this)).subscribe((notifications) => {
            this.notifications = notifications;
        });
    }

    ngAfterViewInit(): void {
        const sessionLoadReady = this.sessionService.isInitialLoadFinished();
        if (sessionLoadReady) {
            this.launchScreen.hide();
        }
        setTimeout(() => {
            this.initialLoadDone = this.sessionService.isInitialLoadFinished();
        }, 100);
    }

    public onContact(): void {
        this.dialog.open(ContactFormDialogComponent, {
            data: {},
            width: '80vw',
            maxWidth: '600px',
        });
    }

    public signOut(): void {
        this.sessionService.signOut();
    }

    public showAbout(): void {
        const aboutData: AboutData = {
            appName: this.translateService.instant('inventory.app.title'),
            copyright: this.translateService.instant('inventory.app.copyright'),
        };

        this.dialog.open(AboutDialogComponent, {
            disableClose: true,
            data: aboutData,
            maxWidth: '80vw',
            width: '460px',
        });
    }

    public onNotificationRemove(notifications: Notification[]): void {
        if (notifications.length === 1) {
            const notificationId = notifications[0].id;
            this.notificationService.delete(notificationId).subscribe(() => {
                this.notifications = this.notifications.filter((n) => n.id !== notificationId);
            });
        } else if (notifications.length > 1) {
            this.notificationService.deleteAll().subscribe(() => {
                this.notifications = [];
            });
        }
    }

    private getToolbarUserFromSession(s: CCSession): CloudToolbarUser {
        const orgId = s.activeMembership?.organizationId;
        return {
            id: s.user.id,
            fullName: s.user.fullName,
            avatarUrl: s.user.avatarUrl ?? undefined,
            activeOrganizationId: orgId ?? '',
            memberships:
                Object.keys(s.user.memberships).length > 0
                    ? s.user.memberships.map((m) => {
                          const orgMembership: Membership = {
                              id: m.id,
                              organization: {
                                  id: m.organizationId,
                                  name: `${m.organization.name}`,
                                  logoUrl: m.organization.logoUrl ?? undefined,
                              },
                              roles: m.ccRoles,
                          };
                          return orgMembership;
                      })
                    : [],
        } as CloudToolbarUser;
    }

    private listenForHidingGlobalLegalLine(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.hideGlobalLegalLine = this.#PAGES_TO_HIDE_GLOBAL_LEGAL_LINE.some((url) =>
                    this.router.url.includes(url)
                );
            });
    }
}
