import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { NavigationService, NavLink } from './navigation.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UiSharedModule } from '../ui-shared.module';

@UntilDestroy()
@Component({
    standalone: true,
    imports: [CommonModule, UiSharedModule, RouterModule],
    selector: 'app-nav',
    template: `
        <div class="hdmui-subnav">
            <nav mat-tab-nav-bar mat-stretch-tabs="false" [tabPanel]="tabPanel">
                @for (link of links$ | async; track link) {
                    <a
                        mat-tab-link
                        [routerLink]="link.path"
                        routerLinkActive
                        #rla="routerLinkActive"
                        [active]="rla.isActive"
                    >
                        {{ link.name | translate }}
                    </a>
                }
            </nav>
            <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
        </div>
    `,
})
export class NavComponent {
    links$: Observable<NavLink[]> = this.navigationService.links$;

    constructor(private readonly navigationService: NavigationService) {}
}
