/**
 * Your application router
 * Your application components should be loaded lazily (see Task Module below)
 */
import { Component, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { SessionAuthGuard } from '@vmi/core';
import { ROUTE_PARAMS, ROUTE_PATH } from '@vmi/shared-models';
import { canActivateVmiAccessGuard } from '@vmi/feature-access-denied';

@Component({
    standalone: true,
    template: '',
})
export class OrganizationProxyComponent {
    constructor(
        private readonly router: Router,
        private readonly sessionService: CCAuthService
    ) {
        const id = this.sessionService.getCurrentMembership()?.organizationId;
        if (id) {
            void this.router.navigateByUrl(`organization/${encodeURIComponent(id)}`);
        }
    }
}

const ROUTES: Routes = [
    {
        path: `${ROUTE_PATH.accessDenied}`,
        loadChildren: () => import('../../projects/feature-access-denied/src/lib/feature-access-denied.module'),
        canActivate: [canActivateVmiAccessGuard(true)],
    },
    {
        path: `organization/:${ROUTE_PARAMS.orgId}`,
        canActivate: [SessionAuthGuard, canActivateVmiAccessGuard()],
        children: [
            {
                path: `${ROUTE_PATH.dashboard}`,
                loadChildren: () => import('../../projects/feature-dashboard/src/lib/feature-dashboard.module'),
            },
            {
                path: `${ROUTE_PATH.inventory}`,
                loadChildren: () => import('../../projects/feature-inventory/src/lib/feature-inventory.module'),
            },
            {
                path: `${ROUTE_PATH.deliveries}`,
                loadChildren: () => import('../../projects/feature-deliveries/src/lib/feature-deliveries.module'),
            },
            {
                path: `${ROUTE_PATH.transactions}`,
                loadChildren: () => import('../../projects/feature-transactions/src/lib/feature-transactions.module'),
            },
            { path: '', redirectTo: ROUTE_PATH.dashboard, pathMatch: 'full' },
        ],
    },
    { path: '**', component: OrganizationProxyComponent },
];
@NgModule({
    imports: [RouterModule.forRoot(ROUTES, { paramsInheritanceStrategy: 'always', useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
