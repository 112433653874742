import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from '@vmi/core';
import { LegalLineComponent, LoadingIndicatorComponent } from '@vmi/ui-presentational';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UiSharedModule } from './ui-shared.module';
import { NavComponent } from './navigation/nav.component';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        UiSharedModule,
        NavComponent,
        LoadingIndicatorComponent,
        LegalLineComponent,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
